import { Box, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Colander } from "@trainwell/colander";
import type { Trainer } from "@trainwell/types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";

export function ScheduleCall() {
  const theme = useTheme();
  const trainerId = useAppSelector((state) => state.switchTrainer.newTrainerId);
  const client = useAppSelector((state) => state.client.client);
  const location = useLocation();
  const [trainer, setTrainer] = useState<Trainer>();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const foundClientID = client?.user_id || urlParams.get("user_id");

  useEffect(() => {
    if (!trainer && trainerId) {
      api.trainers
        .getOne(trainerId)
        .then((trainer) => {
          setTrainer(trainer);
        })
        .catch(() => {
          setTrainer(undefined);
        });
    }
  });

  if (!foundClientID) {
    return (
      <Container maxWidth="md">
        <Typography variant="h1" sx={{ mb: 4, textAlign: "center" }}>
          We ran into a problem finding your client id
        </Typography>
      </Container>
    );
  }

  if (!trainer) {
    return (
      <Container maxWidth="md">
        <Typography variant="h1" sx={{ mb: 4, textAlign: "center" }}>
          We ran into a problem finding your trainer
        </Typography>
      </Container>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          isSmall ? theme.palette.background.paper : "#f6f6f6",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md" sx={{ py: isSmall ? 2 : 4, px: 0 }}>
        <Typography variant="h1" sx={{ mb: 4, textAlign: "center" }}>
          Schedule your call
        </Typography>
        <Typography sx={{ mb: 4, textAlign: "center" }}>
          You and {trainer?.first_name} will meet virtually to continue your
          journey with trainwell.
        </Typography>
        <Colander
          variant={isSmall ? "box" : "card"}
          coach={trainer}
          userId={foundClientID}
          defaultCallType={
            client?.account.dashboard.date_onboarded ? "check_in" : "onboarding"
          }
          disableCallTypeSelection
          disableCheckinToggle
          api={api}
          hideWordmarkMobile
        />
      </Container>
    </Box>
  );
}
