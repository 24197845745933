import { useSubscriptionGroups } from "@trainwell/features/subscription-groups";
import { differenceInMonths } from "date-fns";
import { useAppSelector } from "./stateHooks";

export function useSubscriptionGroupOptions() {
  const client = useAppSelector((state) => state.client.client);
  const { data: subscriptionGroups } = useSubscriptionGroups({
    userId: client?.user_id ?? "",
    queryConfig: {
      enabled: !!client,
    },
  });

  if (!client) {
    return [];
  }

  const now = new Date();

  const clientTenureMonths = differenceInMonths(
    now,
    client.account.membership.date_membership_started ?? now,
  );

  const subscriptionGroup = subscriptionGroups?.at(0);

  return (subscriptionGroup?.options ?? []).filter(
    (option) =>
      !option.required_tenure_months ||
      clientTenureMonths > option.required_tenure_months,
  );
}
