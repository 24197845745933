import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import type { CalendarEvent } from "@trainwell/api-sdk";
import { ColanderLoading } from "@trainwell/colander";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { fetchClient } from "src/slices/clientSlice";
import { SchedulePage } from "./SchedulePage";

export function ReschedulePage() {
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const [event, setEvent] = useState<CalendarEvent>();
  const client = useAppSelector((state) => state.client.client);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (eventId) {
      api.events.getOne(eventId).then((data) => {
        setEvent(data);
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (!client && event?.user_id) {
      dispatch(fetchClient(event.user_id));
    }
  }, [dispatch, event?.user_id]);

  if (!eventId) {
    return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            isSmall ? theme.palette.background.paper : "#f6f6f6",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <ColanderLoading
            variant={isSmall ? "box" : "card"}
            message={"Finding event id"}
          />
        </Container>
      </Box>
    );
  }

  if (!event) {
    return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            isSmall ? theme.palette.background.paper : "#f6f6f6",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <ColanderLoading
            variant={isSmall ? "box" : "card"}
            message={"Loading event"}
          />
        </Container>
      </Box>
    );
  }

  return <SchedulePage event={event} />;
}
