import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import {
  usePauseSubscription,
  useSubscriptions,
} from "@trainwell/features/subscriptions";
import { format, fromUnixTime, startOfDay, subHours } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { hideChat, showChat } from "src/lib/frontChat";
import { formatCentsToDollars, getAvailablePauseDurations } from "src/lib/misc";
import { refetchClient } from "src/slices/clientSlice";

type Props = {
  planId: string;
  open: boolean;
  onClose: () => void;
};

export function PauseDialog({ planId, open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState("");
  const client = useAppSelector((state) => state.client.client);
  const pauseSubscription = usePauseSubscription();
  const { data: subscriptions } = useSubscriptions({
    userId: client?.user_id ?? "",
  });
  const plan = subscriptions?.find((plan) => plan.id === planId);
  const [months, setMonths] = useState(1);
  const pauseDurations = plan ? getAvailablePauseDurations(plan) : [];

  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  useEffect(() => {
    if (planId) {
      setMonths(pauseDurations[0]);
    }
  }, [planId]);

  function handleClose() {
    onClose();

    setReason("");
    setMonths(1);
  }

  if (!plan) {
    return null;
  }

  const dateToStartPause = subHours(
    startOfDay(fromUnixTime(plan.currentPeriodEnd)),
    4,
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (pauseSubscription.isPending) {
          return;
        }

        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Pause plan</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>
          After confirming, your account will pause on{" "}
          {format(dateToStartPause, "MMMM d, yyyy")}.
          <br />
          <br />
          Your subscription will automatically renew for{" "}
          {formatCentsToDollars(plan.price ?? 0)} after your {months} month
          {months > 1 ? "s" : ""} pause. You can also resume early. 😊
        </DialogContentText>
        <TextField
          label="Reason for pause"
          fullWidth
          multiline
          minRows={2}
          variant="outlined"
          value={reason}
          onChange={(event) => {
            setReason(event.target.value);
          }}
          sx={{ my: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            handleClose();
          }}
          disabled={pauseSubscription.isPending}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            pauseSubscription.mutate(
              {
                data: {
                  subscription_id: planId,
                  reason: reason,
                  months: months,
                },
              },
              {
                onSuccess: () => {
                  handleClose();

                  dispatch(refetchClient());
                },
              },
            );
          }}
          loading={pauseSubscription.isPending}
          disabled={isBlank(reason)}
        >
          Confirm pause
        </Button>
      </DialogActions>
    </Dialog>
  );
}
