import AccessibilityRoundedIcon from "@mui/icons-material/AccessibilityRounded";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import PregnantWomanRoundedIcon from "@mui/icons-material/PregnantWomanRounded";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import SelfImprovementRoundedIcon from "@mui/icons-material/SelfImprovementRounded";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import SportsHandballRoundedIcon from "@mui/icons-material/SportsHandballRounded";
import TransgenderRoundedIcon from "@mui/icons-material/TransgenderRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { getSanitizedTrainerName } from "@trainwell/features/trainers";
import {
  trainerEducation,
  type TagExperience as TagExperienceType,
  type TagSpecialty as TagSpecialtyType,
  type Trainer,
  type TrainerAvailabilitySlot,
} from "@trainwell/types";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { api } from "src/lib/trainwellApi";
import { AvailabilityDay } from "./AvailabilityDay";
import { TrainerProfileDialog } from "./TrainerProfileDialog";

export const experienceData: {
  label: string;
  key: TagExperienceType;
  icon: JSX.Element;
}[] = [
  {
    label: "Older populations",
    key: "older_clients",
    icon: <SentimentSatisfiedRoundedIcon />,
  },
  {
    label: "LGBTQ+",
    key: "lgbtq",
    icon: <TransgenderRoundedIcon />,
  },
  {
    label: "Pre/postnatal mothers",
    key: "pre_postnatal",
    icon: <PregnantWomanRoundedIcon />,
  },
  {
    label: "Plus size populations",
    key: "obesity",
    icon: <AccessibilityRoundedIcon />,
  },
  { label: "ADHD", key: "adhd", icon: <PsychologyRoundedIcon /> },
  { label: "Anxiety", key: "anxiety", icon: <PsychologyRoundedIcon /> },
  { label: "Depression", key: "depression", icon: <PsychologyRoundedIcon /> },
  {
    label: "Body dysmorphia",
    key: "body_dysmorphia",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "Auto-immunity",
    key: "auto_immunity",
    icon: <LocalHospitalRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

export const specialtiesData: {
  label: string;
  key: TagSpecialtyType;
  icon: JSX.Element;
}[] = [
  {
    label: "Weight management",
    key: "weight_loss",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "Eating healthier",
    key: "eat_healthy",
    icon: <RestaurantRoundedIcon />,
  },
  {
    label: "Mobility & flexibility",
    key: "mobility_flexibility",
    icon: <EmojiPeopleRoundedIcon />,
  },
  {
    label: "Mental wellness",
    key: "mental_wellness",
    icon: <SelfImprovementRoundedIcon />,
  },
  {
    label: "Reducing fatigue",
    key: "reduce_fatigue",
    icon: <DirectionsRunRoundedIcon />,
  },
  {
    label: "Quality of life",
    key: "quality_of_life",
    icon: <SentimentSatisfiedAltRoundedIcon />,
  },
  {
    label: "Strength training",
    key: "strength",
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    label: "Sports performance",
    key: "sports",
    icon: <SportsHandballRoundedIcon />,
  },
  {
    label: "General health",
    key: "general_health",
    icon: <FavoriteRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

type Props = {
  trainerID: string;
  isTopChoice: boolean;
  loading: boolean;
  selectTrainerHandler: (trainerID: string) => void;
  buttonPrefix?: string;
};

export function TrainerCard({
  trainerID,
  isTopChoice,
  selectTrainerHandler,
  buttonPrefix,
}: Props) {
  const [trainer, setTrainer] = useState<Trainer>();
  const [availabilities, setAvailabilities] = useState<
    TrainerAvailabilitySlot[] | null
  >(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const [chipData, setChipData] = useState<
    { label: string; color: string; icon: JSX.Element }[]
  >([]);

  useEffect(() => {
    if (!trainer) {
      api.trainers
        .getOne(trainerID)
        .then((trainer) => {
          setTrainer(trainer);
        })
        .catch(() => {
          setTrainer(undefined);
        });

      api.trainers
        .getAvailabilities(trainerID)
        .then((res) => {
          setAvailabilities(res.availability_slots);
        })
        .catch(() => {
          setAvailabilities(null);
        });
    }
  }, [trainerID, trainer]);

  useEffect(() => {
    if (trainer) {
      const newChipData: typeof chipData = [];

      if (trainer.years_experience) {
        newChipData.push({
          label: trainer.years_experience + " years of experience",
          color: "#ffedd5",
          icon: <WorkRoundedIcon />,
        });
      }

      trainer.education?.forEach((education) => {
        newChipData.push({
          label: trainerEducation[education],
          color: "#dcfce7",
          icon: <SchoolRoundedIcon />,
        });
      });

      // Shuffle array
      let filteredSpecialties = (
        trainer.onboarding_tags?.specialties ?? []
      ).sort(() => 0.5 - Math.random());

      // Get sub-array of first n elements after shuffled
      filteredSpecialties = filteredSpecialties.slice(0, 2);

      filteredSpecialties.forEach((tag) => {
        newChipData.push({
          label: specialtiesData.find((data) => data.key === tag)?.label ?? "",
          color: "#dbeafe",
          icon: specialtiesData.find((data) => data.key === tag)?.icon ?? <></>,
        });
      });

      // Shuffle array
      let filteredExperience = (trainer.onboarding_tags?.experience ?? []).sort(
        () => 0.5 - Math.random(),
      );

      // Get sub-array of first n elements after shuffled
      filteredExperience = filteredExperience.slice(0, 2);

      filteredExperience.forEach((tag) => {
        newChipData.push({
          label: experienceData.find((data) => data.key === tag)?.label ?? "",
          color: "#f3e8ff",
          icon: experienceData.find((data) => data.key === tag)?.icon ?? <></>,
        });
      });

      setChipData(newChipData);
    }
  }, [trainer]);

  function getAvailabilityDays(): {
    date: Date;
    times: string[];
  }[] {
    const slots = availabilities ?? [];

    if (slots) {
      const availableOnboardingSlots = slots.filter(
        (slot) => !slot.busy && slot.call_type === "check_in",
      );

      //Shift all slot dates to be in local time
      const shiftedSlots = availableOnboardingSlots.map((slot) => {
        return {
          date_start: convertToLocalTime(new Date(slot.date_start)),
          date_end: convertToLocalTime(new Date(slot.date_end)),
        };
      });

      //Compute start of tomorrow in local time
      const startOfTomorrow = startOfDay(addDays(new Date(), 1));

      const availabilityDays: {
        date: Date;
        times: string[];
      }[] = [];

      //Loop through upcoming days and find slots
      for (let i = 0; i < 7; i += 1) {
        const dayStart = addDays(startOfTomorrow, i);
        const dayEnd = endOfDay(dayStart);

        const daySlots = shiftedSlots.filter(
          (slot) =>
            slot.date_start.getTime() >= dayStart.getTime() &&
            slot.date_start.getTime() <= dayEnd.getTime(),
        );

        availabilityDays.push({
          // dayLabel: getUTCDayOfWeekLabel(dayStart), //Use UTC because we already shifted to local time (so "UTC" is local)
          // dayNumber: dayStart.getUTCDate(), //Use UTC because we already shifted to local time (so "UTC" is local)
          date: dayStart,
          times: daySlots.map((slot) => formatUTCTime(slot.date_start)),
        });
      }

      return availabilityDays.filter((day) => day.times.length > 0);
    }

    return [];
  }

  const days = getAvailabilityDays();

  return (
    <>
      <Card
        sx={{
          p: 1,
          position: "relative",
          height: "100%",
        }}
      >
        {trainer ? (
          <Box
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            {isTopChoice && (
              <Box
                sx={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  py: 0.5,
                  px: 1,
                  borderRadius: 1,
                  background: (theme) => theme.palette.success.main,
                  color: "#ffffff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography style={{ fontSize: "14px" }}>Top Choice</Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 2,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  borderRadius: "87.5px",
                  overflow: "hidden",
                }}
              >
                {trainer.headshot_url_hd && (
                  <img
                    src={trainer.headshot_url_hd}
                    width={175}
                    height={175}
                    alt={getSanitizedTrainerName(trainer)}
                  />
                )}
              </Box>
            </Box>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              {getSanitizedTrainerName(trainer)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {chipData.map((data) => {
                return (
                  <Chip
                    key={data.label}
                    icon={data.icon}
                    label={data.label}
                    sx={{
                      backgroundColor: data.color,
                      color: (theme) => theme.palette.primary.main,
                      ".MuiChip-iconColorDefault": {
                        color: (theme) => theme.palette.primary.main,
                      },
                      ".MuiChip-label": {
                        fontWeight: 700,
                      },
                    }}
                    size="small"
                  />
                );
              })}
            </Box>
            <Box
              sx={{ marginTop: "16px", maxWidth: "100%", marginRight: "-16px" }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {trainer.first_name}&apos;s call availability:
              </Typography>
              <Box
                sx={{
                  marginTop: "8px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  maxWidth: "100%",
                  overflowX: "auto",
                }}
              >
                {days.map((day) => (
                  <AvailabilityDay key={day.date.toISOString()} {...day} />
                ))}
              </Box>
              <Typography sx={{ color: "#888" }} variant="caption">
                All times in{" "}
                {new Date()
                  .toLocaleDateString(undefined, {
                    day: "2-digit",
                    timeZoneName: "short",
                  })
                  .substring(4)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "0.5em",
              }}
            >
              <Button
                variant="text"
                sx={{
                  width: "100%",
                  mb: 1,
                }}
                onClick={() => {
                  setProfileOpen(true);
                }}
              >
                Learn More
              </Button>
              <Button
                sx={{
                  width: "100%",
                }}
                onClick={() => {
                  selectTrainerHandler(trainerID);
                }}
              >
                {(buttonPrefix ?? "Switch to ") + trainer.first_name}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "480px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color={"primary"} />
          </Box>
        )}
      </Card>
      {trainer && (
        <TrainerProfileDialog
          isOpen={profileOpen}
          trainer={trainer}
          handleClose={() => {
            setProfileOpen(false);
          }}
          chipData={chipData}
          selectTrainerHandler={selectTrainerHandler}
        />
      )}
    </>
  );
}

/**
 * Converts a UTC Date object to a local Date object.
 * @param {Date} utcDate - The UTC Date object.
 * @returns {Date} - The local Date object.
 */
function convertToLocalTime(utcDate: Date): Date {
  // Ensure the input is a valid Date object
  if (!(utcDate instanceof Date) || isNaN(utcDate.getTime())) {
    throw new Error("Invalid Date object");
  }

  // Get the timezone offset in minutes and convert it to milliseconds
  const timezoneOffset = utcDate.getTimezoneOffset() * 60000;

  // Create a new Date object in local time
  const localTime = new Date(utcDate.getTime() - timezoneOffset);

  return localTime;
}

/**
 * Formats a Date object into a string like "10:00 am" or "3:45 pm".
 * @param {Date} date - The Date object.
 * @returns {string} - The formatted time string.
 */
function formatUTCTime(date: Date): string {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error("Invalid Date object");
  }

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  return `${hours}:${minutesStr} ${ampm}`;
}

function getUTCDayOfWeekLabel(date: Date): string {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error("Invalid Date object");
  }

  // Array of day labels
  const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Get the day of the week (0-6)
  const dayIndex = date.getUTCDay();

  // Return the corresponding day label
  return dayLabels[dayIndex];
}
