import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Box, Button, Card, Chip, Stack, Typography } from "@mui/material";
import { usePaymentMethods } from "@trainwell/features/payment-methods";
import {
  useCancelUpcomingSubscriptionSwitch,
  useRetryCharges,
  useRevokeCancelRequest,
  useRevokePauseRequest,
  type Subscription,
} from "@trainwell/features/subscriptions";
import { format, fromUnixTime, isPast } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useSubscriptionGroupOptions } from "src/hooks/useSubscriptionGroupOptions";
import { formatCentsToDollars, getAvailablePauseDurations } from "src/lib/misc";
import { trackEvent } from "src/slices/analyticsSlice";
import { refetchClient } from "src/slices/clientSlice";
import { PaymentMethodDialog } from "../payment-methods/PaymentMethodDialog";
import { CancelDialog } from "./CancelDialog";
import { FsaDialog } from "./FsaDialog";
import { PauseDialog } from "./PauseDialog";
import { ResumeDialog } from "./ResumeDialog";
import { SwitchSubscriptionDialog } from "./SwitchSubscriptionDialog";

const planStatus: Record<string, string> = {
  incomplete: "Incomplete",
  incomplete_expired: "Incomplete expired",
  trialing: "Trialing",
  active: "Active",
  past_due: "Past due",
  canceled: "Canceled",
  unpaid: "Unpaid",
};

type Props = {
  plan: Subscription;
};

export function SubscriptionCard({ plan }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const { data: paymentMethods } = usePaymentMethods({
    userId: client?.user_id ?? "",
  });
  const subscriptionOptions = useSubscriptionGroupOptions();
  const activeTests = useAppSelector((state) => state.analytics.activeTests);
  const isPayToPauseC = activeTests.includes("pause_test_c");

  const [switchOptionsDialogOpen, setSwitchOptionsDialogOpen] = useState(false);
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
  const [resumeDialogOpen, setResumeDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [paymentMethodDialogOpen, setPaymentMethodDialogOpen] = useState(false);
  const [fsaDialogOpen, setFsaDialogOpen] = useState(false);

  const revokeCancelRequest = useRevokeCancelRequest();
  const revokePauseRequest = useRevokePauseRequest();
  const retryCharges = useRetryCharges();
  const cancelUpcomingSubscriptionSwitch =
    useCancelUpcomingSubscriptionSwitch();

  const { enqueueSnackbar } = useSnackbar();

  const name = plan.setToUpdate
    ? `Updates ${plan.nextInvoiceDate ? format(new Date(plan.nextInvoiceDate), "MMM d") : "--"}`
    : planStatus[plan.status];

  const isPreTrial = Boolean(
    plan.trialEnd && !client?.account.dashboard.date_onboarded,
  );
  const isInTrial = Boolean(
    !isPreTrial && plan.trialEnd && !isPast(fromUnixTime(plan.trialEnd)),
  );

  let intervalString = "per ";

  if (plan.interval_count > 1) {
    intervalString += `${plan.interval_count.toString()} `;
  }

  if (plan.interval === "year") {
    intervalString += "year";
  } else if (plan.interval === "week") {
    intervalString += "week";
  } else if (plan.interval === "day") {
    intervalString += "day";
  } else {
    intervalString += "month";
  }

  if (plan.interval_count > 1) {
    intervalString += "s";
  }

  let message = "";

  if (plan.willCancelOn) {
    if (!client?.account.dashboard.date_onboarded) {
      message = "Plan canceled.";
    } else {
      message = `Cancels on ${format(
        fromUnixTime(plan.willCancelOn),
        "MMMM do, yyyy",
      )}.`;
    }
  } else if (plan.canceledAt) {
    message = `Canceled on ${format(
      fromUnixTime(plan.canceledAt),
      "MMMM do, yyyy",
    )}.`;
  } else if (plan.willPauseOn) {
    message = `Pauses on ${format(
      fromUnixTime(plan.willPauseOn),
      "MMMM do, yyyy",
    )}.`;
  } else if (plan.pausedUntil) {
    message = `Paused until ${format(
      fromUnixTime(plan.pausedUntil),
      "MMMM d, yyyy",
    )}`;
  } else if (plan.setToUpdate) {
    // message = `Updates on ${format(
    //   fromUnixTime(plan.currentPeriodEnd),
    //   "MMMM d, yyyy",
    // )}`;
  } else if (isPreTrial) {
    message = `Your ${
      client?.account.plan.original_trial_length ?? 14
    } day trial begins when you call your trainer.`;
  } else if (isInTrial) {
    message = `Your ${
      client?.account.plan.original_trial_length ?? 14
    } day trial ends on ${format(
      fromUnixTime(plan.trialEnd!),
      "MMMM d, yyyy",
    )}.`;
  } else if (plan.status === "past_due") {
    message = `The most recent payment failed.`;
  } else {
    message = `Renews on ${format(
      fromUnixTime(plan.currentPeriodEnd),
      "MMMM do, yyyy",
    )}.`;
  }

  let couponMessage = "";

  if (plan.coupon) {
    if (plan.coupon.amount_off) {
      couponMessage += `${formatCentsToDollars(plan.coupon.amount_off)}`;
    } else if (plan.coupon.percent_off) {
      couponMessage += `${plan.coupon.percent_off}%`;
    }

    couponMessage += " off";

    if (plan.coupon.duration === "forever") {
      couponMessage += " forever";
    } else if (plan.coupon.duration === "once") {
      couponMessage += " once";
    } else if (plan.coupon.duration === "repeating") {
      couponMessage += ` for ${plan.coupon.duration_in_months} months`;
    }
  }

  const availablePauseDurations = getAvailablePauseDurations(plan);

  return (
    <>
      <Card sx={{ p: 2 }} variant="outlined">
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>trainwell</Typography>
            {plan.price && (
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                {formatCentsToDollars(plan.price)} {intervalString}
              </Typography>
            )}
            {couponMessage && (
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                {couponMessage}
              </Typography>
            )}
          </Box>
          <Stack spacing={1} direction="row">
            {isPreTrial || isInTrial ? (
              <Chip size="small" label={`Trial`} />
            ) : plan.pausedUntil ? (
              <Chip size="small" label={"Paused"} />
            ) : (
              <Chip
                size="small"
                color={
                  plan.setToUpdate
                    ? "info"
                    : plan.status === "active"
                      ? "success"
                      : plan.status === "past_due" || plan.status === "unpaid"
                        ? "error"
                        : "default"
                }
                label={name}
              />
            )}
            {plan.willCancelOn && client?.account.dashboard.date_onboarded && (
              <Chip
                size="small"
                label={`Cancels ${format(
                  fromUnixTime(plan.willCancelOn),
                  "MMM d",
                )}`}
              />
            )}
            {plan.willPauseOn && (
              <Chip
                size="small"
                label={`Pauses ${format(
                  fromUnixTime(plan.willPauseOn),
                  "MMM d",
                )}`}
              />
            )}
          </Stack>
        </Box>
        {plan.nextInvoiceDate && !plan.willPauseOn && (
          <Typography
            sx={{ mb: 1 }}
          >{`Next invoice ${client?.account.dashboard.date_onboarded ? `on ${format(new Date(plan.nextInvoiceDate), "MMM d, yyyy")} for ` : "after trial "}${plan.nextInvoiceAmount ? formatCentsToDollars(plan.nextInvoiceAmount) : "$0"}`}</Typography>
        )}
        <Typography sx={{ mb: 1 }}>{message}</Typography>
        <Stack direction={"row"} spacing={1} alignItems="center">
          {plan.status === "past_due" &&
            !plan.willCancelOn &&
            !plan.willPauseOn &&
            !plan.pausedUntil && (
              <Button
                size="small"
                variant="contained"
                loading={retryCharges.isPending}
                onClick={() => {
                  retryCharges.mutate(
                    {
                      data: {
                        userId: client?.user_id ?? "",
                      },
                    },
                    {
                      onError: () => {
                        enqueueSnackbar({
                          variant: "error",
                          message: "Failed to retry charge",
                        });
                      },
                      onSuccess: () => {
                        enqueueSnackbar({
                          variant: "success",
                          message: "Charge success",
                        });
                      },
                    },
                  );

                  dispatch(
                    trackEvent({
                      event_type: "retry_charge_button_click",
                      event_content: { location: "plan_card" },
                    }),
                  );
                }}
              >
                Retry charge
              </Button>
            )}
          {plan.willCancelOn &&
            !(
              plan.status === "canceled" || plan.status === "incomplete_expired"
            ) && (
              <Button
                variant="contained"
                onClick={() => {
                  if (!paymentMethods || paymentMethods.length === 0) {
                    setPaymentMethodDialogOpen(true);
                  } else {
                    revokeCancelRequest.mutate(plan.id);
                  }
                }}
                loading={revokeCancelRequest.isPending}
              >
                Don&apos;t cancel
              </Button>
            )}
          {plan.pausedUntil && (
            <Button
              variant="contained"
              onClick={() => {
                setResumeDialogOpen(true);
              }}
              startIcon={<PlayArrowRoundedIcon />}
            >
              Resume plan
            </Button>
          )}
          {!(
            plan.status === "canceled" ||
            plan.status === "past_due" ||
            plan.status === "incomplete" ||
            plan.status === "incomplete_expired"
          ) &&
            client?.account.membership.state_pending === null &&
            !plan.willCancelOn &&
            !plan.willPauseOn &&
            !plan.pausedUntil &&
            !isPreTrial &&
            !isInTrial &&
            availablePauseDurations.length > 0 &&
            !isPayToPauseC && (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setPauseDialogOpen(true);

                  dispatch(
                    trackEvent({
                      event_type: "pause_button_click",
                      event_content: { location: "plan_card" },
                    }),
                  );
                }}
              >
                Pause
              </Button>
            )}
          {plan.willPauseOn && (
            <Button
              size="small"
              variant="text"
              onClick={() => {
                revokePauseRequest.mutate(plan.id, {
                  onSuccess: () => {
                    dispatch(refetchClient());
                  },
                });
              }}
              loading={revokePauseRequest.isPending}
            >
              Don&apos;t pause
            </Button>
          )}
          {!(
            plan.status === "canceled" ||
            plan.status === "incomplete" ||
            plan.status === "incomplete_expired"
          ) &&
            !plan.willCancelOn &&
            !plan.willPauseOn && (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setCancelDialogOpen(true);

                  dispatch(
                    trackEvent({
                      event_type: "cancel_button_click",
                      event_content: { location: "plan_card" },
                    }),
                  );
                }}
              >
                {plan.status === "past_due" ? "Cancel now" : "Cancel"}
              </Button>
            )}
          {!(
            plan.status === "canceled" ||
            plan.status === "past_due" ||
            plan.status === "incomplete" ||
            plan.status === "incomplete_expired"
          ) &&
            !plan.setToUpdate &&
            subscriptionOptions.filter(
              (option) => option.stripe_price_id !== plan.stripe_price_id,
            ).length > 0 && (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setSwitchOptionsDialogOpen(true);

                  dispatch(
                    trackEvent({
                      event_type: "switch_button_click",
                      event_content: { location: "plan_card" },
                    }),
                  );
                }}
              >
                Switch plan
              </Button>
            )}
          {!(
            plan.status === "canceled" ||
            plan.status === "incomplete" ||
            plan.status === "past_due" ||
            plan.status === "incomplete_expired"
          ) &&
            plan.setToUpdate && (
              <Button
                size="small"
                variant="text"
                loading={cancelUpcomingSubscriptionSwitch.isPending}
                onClick={() => {
                  cancelUpcomingSubscriptionSwitch.mutate({
                    data: {
                      subscription_id: plan.id,
                    },
                  });

                  dispatch(
                    trackEvent({
                      event_type: "cancel_switch_button_click",
                      event_content: { location: "plan_card" },
                    }),
                  );
                }}
              >
                Cancel switch
              </Button>
            )}
        </Stack>
      </Card>
      {plan.status !== "canceled" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 0.5,
          }}
        >
          <Button
            sx={{
              textDecoration: "underline",
            }}
            size="small"
            variant="text"
            onClick={() => {
              setFsaDialogOpen(true);
            }}
          >
            Want to pay with HSA/FSA?
          </Button>
        </Box>
      )}
      <FsaDialog
        open={fsaDialogOpen}
        onClose={() => {
          setFsaDialogOpen(false);
        }}
      />
      {pauseDialogOpen && (
        <PauseDialog
          open={pauseDialogOpen}
          onClose={() => {
            setPauseDialogOpen(false);
          }}
          planId={plan.id}
        />
      )}
      {resumeDialogOpen && (
        <ResumeDialog
          open={resumeDialogOpen}
          onClose={() => {
            setResumeDialogOpen(false);
          }}
          planId={plan.id}
        />
      )}
      {cancelDialogOpen && (
        <CancelDialog
          open={cancelDialogOpen}
          onClose={() => {
            setCancelDialogOpen(false);
          }}
          planId={plan.id}
        />
      )}
      {switchOptionsDialogOpen && (
        <SwitchSubscriptionDialog
          open={switchOptionsDialogOpen}
          subOptions={subscriptionOptions}
          onClose={() => {
            setSwitchOptionsDialogOpen(false);
          }}
        />
      )}
      <PaymentMethodDialog
        open={paymentMethodDialogOpen}
        onClose={() => {
          setPaymentMethodDialogOpen(false);

          revokeCancelRequest.mutate(plan.id);
        }}
        renewPlanOnSuccess
      />
    </>
  );
}
