import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import {
  alpha,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { CalendarEvent } from "@trainwell/api-sdk";
import { Colander, ColanderLoading } from "@trainwell/colander";
import { useClientMeetings } from "@trainwell/features/client-meetings";
import { getSanitizedTrainerName } from "@trainwell/features/trainers";
import type { Trainer } from "@trainwell/types";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { hideChat } from "src/lib/frontChat";
import { api } from "src/lib/trainwellApi";
import { trackEvent } from "src/slices/analyticsSlice";
import { handleAuthWeak } from "src/slices/authSlice";

type Props = {
  event?: CalendarEvent;
};

export function SchedulePage({ event }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const location = useLocation();
  const [trainer, setTrainer] = useState<Trainer>();
  const [interimWarningOpen, setInterimWarningOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const foundTrainerId = urlParams.get("trainer_id") ?? client?.trainer_id;
  const [disableCallTypeSelection, setDisableCallTypeSelection] =
    useState(false);
  const [defaultCallType, setDefaultCallType] = useState<string>();
  const {
    data: calls,
    isPending: isCallsPending,
    isError: isCallsError,
  } = useClientMeetings({
    userId: client?.user_id ?? "",
    queryConfig: {
      enabled: Boolean(client?.user_id),
    },
  });

  useEffect(() => {
    if (client?.trainer_id_interim) {
      setInterimWarningOpen(true);
    }
  }, [client?.trainer_id_interim]);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const foundClientID = urlParams.get("user_id");

    if (foundClientID && !client) {
      dispatch(handleAuthWeak(foundClientID));
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (client?.user_id) {
      api.clients.getForcedCallType(client.user_id).then((data) => {
        if (data.force) {
          setDisableCallTypeSelection(true);
          setDefaultCallType(data.call_type);
        }
      });
    }
  }, [client?.user_id]);

  useEffect(() => {
    hideChat();
  });

  useEffect(() => {
    if (!trainer && foundTrainerId) {
      api.trainers
        .getOne(foundTrainerId)
        .then((trainer) => {
          setTrainer(trainer);
        })
        .catch(() => {
          setTrainer(undefined);
        });
    }
  }, [foundTrainerId]);

  if ((!trainer && (foundTrainerId || !client)) || !client) {
    return (
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Container maxWidth="md">
          <ColanderLoading
            variant={isSmall ? "box" : "card"}
            message={"Loading trainer"}
          />
        </Container>
      </Box>
    );
  }

  if (!trainer) {
    return (
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Container maxWidth="md">
          <Typography>Error loading trainer</Typography>
        </Container>
      </Box>
    );
  }

  if (
    client.account.membership.state !== "active" &&
    client.account.membership.state !== "past_due"
  ) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Container maxWidth="md" sx={{ py: isSmall ? undefined : 2 }}>
        {!isCallsPending &&
          !isCallsError &&
          calls &&
          calls.length === 0 &&
          client.tests?.includes("jan_sms_call_reminder_treatment") && (
            <Card
              variant="outlined"
              sx={{
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                p: 1,
                my: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <SwapHorizRoundedIcon color="primary" sx={{ mr: 1 }} />
                <Typography>
                  Want a better call time? Switch trainers!
                </Typography>
              </Box>
              <Button
                size="small"
                href={`/change-coach?user_id=${client.user_id}`}
                fullWidth={isSmall}
                onClick={() => {
                  dispatch(
                    trackEvent({
                      event_type: "ob_call_switch_trainer_clicked",
                    }),
                  );
                }}
              >
                See more amazing trainers
              </Button>
            </Card>
          )}
        {!client ? (
          <ColanderLoading
            variant={isSmall ? "box" : "card"}
            message="Loading client"
          />
        ) : (
          <Colander
            coach={trainer}
            userId={client.user_id}
            event={event}
            disableCallTypeSelection={disableCallTypeSelection}
            defaultCallType={defaultCallType}
            variant={isSmall ? "box" : "card"}
            api={api}
            hideWordmarkMobile
          />
        )}
      </Container>
      {client.trainer_id_interim && (
        <Dialog
          open={interimWarningOpen}
          onClose={() => {
            setInterimWarningOpen(false);
          }}
        >
          <DialogTitle>You have an interim trainer</DialogTitle>
          <DialogContent>
            <Typography>
              You cannot schedule calls with an interim trainer, please schedule
              with trainer {getSanitizedTrainerName(trainer)} or wait until they
              are back.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setInterimWarningOpen(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
