import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import WithAuth from "src/components/misc/WithAuth";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchSubscriptionGroup } from "src/slices/paymentSlice";
import PayPageContainer from "./Pay/PayPageContainer";
import { SelectCoachPage } from "./SelectCoachPage";

export function UpgradeFreemiumPage() {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const [trainerId, setTrainerId] = useState<string>();

  useEffect(() => {
    if (client) {
      dispatch(fetchSubscriptionGroup(client.user_id));
    }
  }, [client?.user_id]);

  return (
    <WithAuth>
      <>
        {!trainerId && (
          <SelectCoachPage
            selectCoach={(trainerId) => {
              setTrainerId(trainerId);
            }}
          />
        )}
        {trainerId && (
          <Box sx={{ mt: 2 }}>
            <PayPageContainer trainerId={trainerId} />
          </Box>
        )}
      </>
    </WithAuth>
  );
}
