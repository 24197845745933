import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import analyticsReducer from "src/slices/analyticsSlice";
import authReducer from "src/slices/authSlice";
import clientReducer from "src/slices/clientSlice";
import paymentReducer from "src/slices/paymentSlice";
import switchTrainerReducer from "src/slices/switchTrainerSlice";
import trainerReducer from "src/slices/trainerSlice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  attachReduxState: false,
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    client: clientReducer,
    switchTrainer: switchTrainerReducer,
    trainer: trainerReducer,
    analytics: analyticsReducer,
    payment: paymentReducer,
  },
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
