import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Link,
} from "@mui/material";
import BootstrapDialogTitle from "src/components/materialWrappers/DialogTitle";
import { useAppSelector } from "src/hooks/stateHooks";

type Props = {
  open: boolean;
  onClose: () => void;
};

export function FsaDialog({ open, onClose }: Props) {
  const userId = useAppSelector((state) => state.client.client?.user_id);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <BootstrapDialogTitle onClose={onClose}>
        How to pay with HSA/FSA
      </BootstrapDialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          We&apos;re making it easier to afford trainwell&apos;s personal
          training by allowing you to get reimbursed through your HSA or FSA.
        </DialogContentText>
        <DialogContentText sx={{ fontWeight: "bold" }}>
          How it Works:
        </DialogContentText>
        <DialogContentText>
          <ol>
            <li>
              Go to{" "}
              <Link
                sx={{
                  textDecoration: "underline",
                }}
                href={`https://form.typeform.com/to/mG1mvvah#client_reference_id=${userId}`}
              >
                our partner Flex
              </Link>{" "}
              for an online consultation to confirm your eligibility.{" "}
              <b>No appointment or video call necessary.</b>
            </li>
            <li>
              Receive your Letter of Medical Necessity, valid for 12 months, via
              email within a few days.
            </li>
            <li>
              Submit your Letter, along with your trainwell receipt, to your HSA
              or FSA administrator.
            </li>
            <li>Get reimbursed on your membership (U.S. residents only).</li>
          </ol>
        </DialogContentText>
        <Button
          fullWidth
          href={`https://form.typeform.com/to/mG1mvvah#client_reference_id=${userId}`}
        >
          Get reimbursed
        </Button>
      </DialogContent>
    </Dialog>
  );
}
