import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  getAuth,
  sendSignInLinkToEmail,
  type ConfirmationResult,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router";
import { isValidEmail } from "src/lib/misc";

type Props = {
  open: boolean;
  onClose: () => void;
};

export function EmailDialog({ open, onClose }: Props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();

  function onSignInSubmit() {
    const auth = getAuth();
    //${window.location.hostname}
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `https://account.trainwell.net/finish-sign-in`,
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: "com.deltaband.DeltaBandBeta",
      },
      android: {
        packageName: "com.mycopilot.copilotapp",
        installApp: true,
        minimumVersion: "12",
      },
      dynamicLinkDomain: "link.mycopilot.com",
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
        setSentEmail(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }

  function handleClose() {
    onClose();
    setEmail("");
    setSentEmail(false);
    setConfirmationResult(undefined);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      {!sentEmail ? (
        <>
          <DialogTitle>Sign in with email</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the same email you use to sign into the trainwell app.
            </DialogContentText>
            <TextField
              autoFocus
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              placeholder="alexsmith@example.com"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              sx={{ my: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (!isValidEmail(email)) {
                  return;
                }

                onSignInSubmit();
              }}
              disabled={!isValidEmail(email)}
            >
              Send email link
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Check your email</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We sent a link to your email. Open your email and click the link
              on this device (don&apos;t use a different device).
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleClose}>
              Ok, I&apos;ll check my email
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
