import * as Sentry from "@sentry/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import type { TrainwellApi } from "@trainwell/api-sdk";
import { createSetupIntent } from "@trainwell/features/payment-methods";
import { useState } from "react";
import { TrainwellError } from "../errors/TrainwellError";
import { useValidatePaymentMethod } from "./useValidatePaymentMethod";

type Props = {
  api: TrainwellApi;
  userId: string;
  onSuccess: () => void;
};

/**
 * Most of this code should match the Stripe documentation
 *
 * @link https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=setup#charge-saved-payment-method
 */
export function useAddPaymentMethod({ api, userId, onSuccess }: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();
  const { validateSetupIntent, validationMessage } = useValidatePaymentMethod({
    api: api,
    userId: userId,
    onSuccess: () => {
      onSuccess();
      setSubmitting(false);
      setPaymentErrorMessage(undefined);
    },
    onFail: () => {
      setSubmitting(false);
      setPaymentErrorMessage(undefined);
    },
  });

  async function addPaymentMethod(makeDefault: boolean) {
    // -
    // Validate current variables
    // -

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded
      console.warn("Stripe.js hasn't yet loaded");
      return;
    }

    if (submitting || !userId) {
      // General guards
      console.warn("Already submitting or no client");
      return;
    }

    setSubmitting(true);

    // -
    // Validate card info
    // -

    // Do not block this code with async logic. Apple Pay requires that is is run
    // ASAP in order to consider it within an "event handler"
    const { error: validationError } = await elements.submit();

    if (validationError) {
      Sentry.captureException(
        new TrainwellError({
          name: "PaymentValidationError",
          message: validationError.message,
          extraData: {
            user_id: userId,
          },
        }),
        {
          level: "info",
        },
      );

      setPaymentErrorMessage(validationError.message);

      setSubmitting(false);

      return;
    }

    // This creates a stripe customer and returns a client secret
    // allowing us to securely connect a card with their stripe account
    const { client_secret } = await createSetupIntent({
      data: { userId },
    }).catch((error) => {
      Sentry.captureException(error);

      setPaymentErrorMessage(
        "Something on our end went wrong, please refresh and try again.",
      );

      setSubmitting(false);

      return { client_secret: null };
    });

    if (!client_secret) {
      return;
    }

    const { error: setupError, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/payment-method-validation?make_default=${encodeURIComponent(makeDefault)}`,
      },
      redirect: "if_required",
      clientSecret: client_secret,
    });

    if (setupError) {
      console.error("Stripe: error confirming payment", setupError);

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to the client (for example, payment
      // details incomplete)

      Sentry.captureException(
        new TrainwellError({
          name: "PaymentError",
          message: setupError.message,
          extraData: {
            user_id: userId,
            code: setupError.code,
          },
        }),
      );

      setPaymentErrorMessage(setupError.message);

      setSubmitting(false);

      return;
    } else if (setupIntent) {
      validateSetupIntent(setupIntent, makeDefault);
    } else {
      // Client will be redirected to PaymentMethodValidationPage.tsx
      // This page will handle the validation charge and setting the payment method as default
    }
  }

  return {
    addPaymentMethod,
    submitting,
    paymentErrorMessage,
    validationMessage,
  };
}
