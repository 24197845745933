import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import LoadingPage from "src/components/misc/LoadingPage";
import { isValidEmail } from "src/lib/misc";

export function FinishSignInPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const foundEmail = window.localStorage.getItem("emailForSignIn");

  if (!email && foundEmail) {
    setEmail(foundEmail);
  }

  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      const email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        return;
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          navigate("/");
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, [email]);

  if (email) {
    return <LoadingPage message="Signing in with email" />;
  }

  return (
    <Box>
      <Dialog
        open={!email}
        onClose={() => {
          navigate("/sign-in");
        }}
      >
        <DialogTitle>Re-enter your email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the same email you used to sign in.
          </DialogContentText>
          <TextField
            autoFocus
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            placeholder="alexsmith@example.com"
            value={newEmail}
            onChange={(event) => {
              setNewEmail(event.target.value);
            }}
            sx={{ my: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              navigate("/sign-in");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (isValidEmail(newEmail)) {
                setEmail(newEmail);
              }
            }}
            disabled={!isValidEmail(newEmail)}
          >
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
