import type { Subscription } from "@trainwell/features/subscriptions";

export function isValidEmail(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regex.test(email);
}

export function formatCentsToDollars(cents: number) {
  const dollarNumber = cents / 100;
  const dollars = dollarNumber.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return dollars;
}

/** Returns possible pause durations for a given plan in months */
export function getAvailablePauseDurations(plan: Subscription): number[] {
  if (plan.interval !== "month") {
    return [];
  }

  let availablePauseDurations = [1, 2, 3];

  availablePauseDurations = availablePauseDurations.filter(
    (duration) => duration % plan.interval_count === 0,
  );

  return availablePauseDurations;
}
