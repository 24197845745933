import * as Sentry from "@sentry/react";
import { Navigate, createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";
import DownloadAppPage from "src/components/pages/DownloadAppPage";
import Layout from "src/components/pages/Layout";
import SurveyPage from "src/components/pages/SurveyPage";
import { FinishSignInPage } from "src/features/auth/FinishSignInPage";
import { SignInPage } from "src/features/auth/SignInPage";
import { VerifySession } from "src/features/auth/VerifySession";
import { BillingPage } from "src/features/billing/BillingPage";
import { UpgradeFreemiumPage } from "src/features/freemium/UpgradeFreemiumPage";
import { LiveTrainingPage } from "src/features/live-training/LiveTrainingPage";
import { CallsPage } from "src/features/manage-calls/CallsPage";
import { PaymentMethodValidationPage } from "src/features/payment-methods/PaymentMethodValidationPage";
import { CancelPage } from "src/features/scheduling/CancelPage";
import { ReschedulePage } from "src/features/scheduling/ReschedulePage";
import { SchedulePage } from "src/features/scheduling/SchedulePage";
import { Done } from "src/features/switch-trainer/Done";
import { ScheduleCall } from "src/features/switch-trainer/ScheduleCall";
import { SwitchTrainerPage } from "src/features/switch-trainer/SwitchTrainerPage";
import { TippingPage } from "src/features/tipping/TippingPage";
import { Root } from "./Root";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "*",
    element: <Root />,
    children: [
      {
        path: "sign-in",
        element: <SignInPage />,
      },
      {
        path: "finish-sign-in",
        element: <FinishSignInPage />,
      },
      {
        path: "survey",
        element: <SurveyPage />,
      },
      {
        path: "survey",
        element: <SurveyPage />,
      },
      {
        path: "download-app",
        element: <DownloadAppPage />,
      },
      {
        path: "verify-session/:sessionId/:path",
        element: <VerifySession />,
      },
      {
        path: "verify-session/:sessionId",
        element: <VerifySession />,
      },
      {
        path: "*",
        element: <Layout />,
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={"/billing" + location.search}
                state={{ searchQuery: window.location.search }}
              />
            ),
          },
          {
            path: "tip-coach",
            element: <TippingPage />,
          },
          {
            path: "change-coach",
            element: <SwitchTrainerPage />,
          },
          {
            path: "schedule-call",
            element: <ScheduleCall />,
          },
          {
            path: "done",
            element: <Done />,
          },
          {
            path: "schedule",
            element: <SchedulePage />,
          },
          {
            path: "cancel/:eventId",
            element: <CancelPage />,
          },
          {
            path: "reschedule/:eventId",
            element: <ReschedulePage />,
          },
          {
            path: "upgrade-account",
            element: <UpgradeFreemiumPage />,
          },
          {
            path: "billing",
            element: <BillingPage />,
          },
          {
            path: "payment-method-validation",
            element: <PaymentMethodValidationPage />,
          },
          {
            path: "calls",
            element: <CallsPage />,
          },
          {
            path: "live-training",
            element: <LiveTrainingPage />,
          },
        ],
      },
    ],
  },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
