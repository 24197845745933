import AccessibilityRoundedIcon from "@mui/icons-material/AccessibilityRounded";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import PregnantWomanRoundedIcon from "@mui/icons-material/PregnantWomanRounded";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import SelfImprovementRoundedIcon from "@mui/icons-material/SelfImprovementRounded";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import SportsHandballRoundedIcon from "@mui/icons-material/SportsHandballRounded";
import TransgenderRoundedIcon from "@mui/icons-material/TransgenderRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import {
  getSanitizedTrainerName,
  useTrainer,
} from "@trainwell/features/trainers";
import {
  trainerEducation,
  type TagExperience as TagExperienceType,
  type TagSpecialty as TagSpecialtyType,
} from "@trainwell/types";
import { useEffect, useState } from "react";
import LoadingComponent from "src/components/misc/LoadingComponent";
import { TrainerProfileDialog } from "src/features/switch-trainer/TrainerProfileDialog";

export const experienceData: {
  label: string;
  key: TagExperienceType;
  icon: JSX.Element;
}[] = [
  {
    label: "Older populations",
    key: "older_clients",
    icon: <SentimentSatisfiedRoundedIcon />,
  },
  {
    label: "LGBTQ+",
    key: "lgbtq",
    icon: <TransgenderRoundedIcon />,
  },
  {
    label: "Pre/postnatal mothers",
    key: "pre_postnatal",
    icon: <PregnantWomanRoundedIcon />,
  },
  {
    label: "Plus size populations",
    key: "obesity",
    icon: <AccessibilityRoundedIcon />,
  },
  { label: "ADHD", key: "adhd", icon: <PsychologyRoundedIcon /> },
  { label: "Anxiety", key: "anxiety", icon: <PsychologyRoundedIcon /> },
  { label: "Depression", key: "depression", icon: <PsychologyRoundedIcon /> },
  {
    label: "Body dysmorphia",
    key: "body_dysmorphia",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "Auto-immunity",
    key: "auto_immunity",
    icon: <LocalHospitalRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

export const specialtiesData: {
  label: string;
  key: TagSpecialtyType;
  icon: JSX.Element;
}[] = [
  {
    label: "Weight management",
    key: "weight_loss",
    icon: <AccessibilityRoundedIcon />,
  },
  {
    label: "Eating healthier",
    key: "eat_healthy",
    icon: <RestaurantRoundedIcon />,
  },
  {
    label: "Mobility & flexibility",
    key: "mobility_flexibility",
    icon: <EmojiPeopleRoundedIcon />,
  },
  {
    label: "Mental wellness",
    key: "mental_wellness",
    icon: <SelfImprovementRoundedIcon />,
  },
  {
    label: "Reducing fatigue",
    key: "reduce_fatigue",
    icon: <DirectionsRunRoundedIcon />,
  },
  {
    label: "Quality of life",
    key: "quality_of_life",
    icon: <SentimentSatisfiedAltRoundedIcon />,
  },
  {
    label: "Strength training",
    key: "strength",
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    label: "Sports performance",
    key: "sports",
    icon: <SportsHandballRoundedIcon />,
  },
  {
    label: "General health",
    key: "general_health",
    icon: <FavoriteRoundedIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRoundedIcon />,
  },
];

type Props = {
  trainerId: string;
  onSelectTrainer: (trainerID: string) => void;
};

export function ChangeTrainerCardSmall({ trainerId, onSelectTrainer }: Props) {
  const { data: trainer, isPending, isError } = useTrainer({ trainerId });
  const [profileOpen, setProfileOpen] = useState(false);
  const [chipData, setChipData] = useState<
    { label: string; color: string; icon: JSX.Element }[]
  >([]);

  useEffect(() => {
    if (trainer) {
      const newChipData: typeof chipData = [];

      trainer.education?.forEach((education) => {
        newChipData.push({
          label: trainerEducation[education],
          color: "#B3E5B6",
          icon: <SchoolRoundedIcon />,
        });
      });

      if (trainer.years_experience) {
        newChipData.push({
          label: trainer.years_experience + " years",
          color: "#FFDD99",
          icon: <WorkRoundedIcon />,
        });
      }

      // Shuffle array
      let filteredSpecialties = (
        trainer.onboarding_tags?.specialties ?? []
      ).sort(() => 0.5 - Math.random());

      // Get sub-array of first n elements after shuffled
      filteredSpecialties = filteredSpecialties.slice(0, 2);

      filteredSpecialties.forEach((tag) => {
        newChipData.push({
          label: specialtiesData.find((data) => data.key === tag)?.label ?? "",
          color: "#FFB589",
          icon: specialtiesData.find((data) => data.key === tag)?.icon ?? <></>,
        });
      });

      // Shuffle array
      let filteredExperience = (trainer.onboarding_tags?.experience ?? []).sort(
        () => 0.5 - Math.random(),
      );

      // Get sub-array of first n elements after shuffled
      filteredExperience = filteredExperience.slice(0, 2);

      filteredExperience.forEach((tag) => {
        newChipData.push({
          label: experienceData.find((data) => data.key === tag)?.label ?? "",
          color: "#A7B2E8",
          icon: experienceData.find((data) => data.key === tag)?.icon ?? <></>,
        });
      });

      setChipData(newChipData);
    }
  }, [trainer]);

  if (isError) {
    return (
      <Card sx={{ p: 2 }}>
        <Typography>Error loading trainer</Typography>
      </Card>
    );
  }

  if (isPending) {
    return (
      <Card sx={{ p: 2 }}>
        <LoadingComponent message="Loading trainer" />
      </Card>
    );
  }

  return (
    <>
      <Card
        sx={{
          p: 2,
        }}
      >
        {trainer ? (
          <Box sx={{ display: "flex" }}>
            {trainer.headshot_url_hd && (
              <img
                src={trainer.headshot_url_hd}
                width={80}
                height={80}
                alt={getSanitizedTrainerName(trainer)}
                style={{
                  objectFit: "cover",
                  borderRadius: "40px",
                }}
              />
            )}
            <Box
              sx={{
                flex: 1,
                ml: 2,
                minWidth: 0,
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 1 }}>
                {getSanitizedTrainerName(trainer)}
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    fontWeight: "normal",
                    display: "inline",
                    ml: 1,
                  }}
                  component={"span"}
                >
                  {trainer.years_experience} years of experience
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                }}
              >
                {trainer.short_about}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    onSelectTrainer(trainerId);
                  }}
                  size="small"
                >
                  Switch to {trainer.first_name}
                </Button>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setProfileOpen(true);
                  }}
                  sx={{ ml: 1 }}
                >
                  Read More
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "480px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color={"primary"} />
          </Box>
        )}
      </Card>
      {trainer && (
        <TrainerProfileDialog
          isOpen={profileOpen}
          trainer={trainer}
          handleClose={() => {
            setProfileOpen(false);
          }}
          chipData={chipData}
          selectTrainerHandler={onSelectTrainer}
        />
      )}
    </>
  );
}
