import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  getAuth,
  signInWithPhoneNumber,
  type ConfirmationResult,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router";
import PhoneNumberInput from "src/components/misc/PhoneNumberInput";

type Props = {
  open: boolean;
  onClose: () => void;
  disableRedirect?: boolean;
};

export function PhoneNumberDialog({ open, onClose, disableRedirect }: Props) {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const [loading, setLoading] = useState(false);

  function onSignInSubmit() {
    console.log("Auth: phone number submitted");

    setLoading(true);

    // @ts-expect-error
    const appVerifier = window.recaptchaVerifier;

    const auth = getAuth();
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        // console.log("conf", confirmationResult);
        // window.confirmationResult = confirmationResult;
        // ...
        setLoading(false);
        setConfirmationResult(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.error(error);
      });
  }

  function finishSignIn() {
    if (!confirmationResult) {
      return;
    }

    setLoading(true);

    confirmationResult
      .confirm(confirmationCode)
      .then((result) => {
        console.log("Auth: phone number sign in success");

        // User signed in successfully.
        const user = result.user;
        // ...
        handleClose();
        if (!disableRedirect) {
          console.log("Auth: redirect to home");
          navigate("/" + location.search);
        }

        setLoading(false);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        setLoading(false);
      });
  }

  function handleClose() {
    onClose();
    setPhoneNumber("");
    setConfirmationCode("");
    setConfirmationResult(undefined);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      {!confirmationResult ? (
        <>
          <DialogTitle>Sign in with phone number</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the same phone number you use to sign into the trainwell
              app.
            </DialogContentText>
            <PhoneNumberInput
              defaultCountry="us"
              preferredCountries={["us", "gb"]}
              disableAreaCodes={true}
              onChange={(e: any) => {
                const phoneNumber = e.replace(/\D/g, "");

                setPhoneNumber("+" + phoneNumber);
              }}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  onSignInSubmit();
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onSignInSubmit();
              }}
              loading={loading}
            >
              Send confirmation text
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Enter confirmation code</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We just sent you a text. Enter the 6-digit code here.
            </DialogContentText>
            <TextField
              autoFocus
              label="Confirmation code"
              type="number"
              fullWidth
              variant="outlined"
              placeholder="123456"
              value={confirmationCode}
              onChange={(event) => {
                setConfirmationCode(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  finishSignIn();
                }
              }}
              sx={{ my: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                finishSignIn();
              }}
              autoFocus
              loading={loading}
            >
              Sign in
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
