import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  alpha,
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid2,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { usePaymentMethods } from "@trainwell/features/payment-methods";
import { useTipTrainer } from "@trainwell/features/subscriptions";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { PaymentMethodDialog } from "../payment-methods/PaymentMethodDialog";

type Props = {
  sx?: SxProps<Theme>;
};

const tipAmounts = [10, 20, 50];

export function TippingOptions({ sx = [] }: Props) {
  const [paymentMethodDialogOpen, setPaymentMethodDialogOpen] = useState(false);
  const tipTrainer = useTipTrainer();
  const client = useAppSelector((state) => state.client.client);

  const { data: paymentMethods } = usePaymentMethods({
    userId: client?.user_id ?? "",
  });
  const defaultPaymentMethod = paymentMethods?.find((pm) => pm.is_default);

  const [selectedTipIndex, setSelectedTipIndex] = useState<number | null>(null);
  const [customTipAmount, setCustomTipAmount] = useState<string>("");
  const [allowError, setAllowError] = useState(false);

  const isInvalidTipAmount = Boolean(
    customTipAmount &&
      (isNaN(Number(customTipAmount)) ||
        customTipAmount.includes(".") ||
        Number(customTipAmount) < 10 ||
        Number(customTipAmount) > 500),
  );

  const tipAmount =
    selectedTipIndex !== null
      ? tipAmounts[selectedTipIndex]
      : Number(customTipAmount);

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {!defaultPaymentMethod && (
        <Card
          sx={{
            px: 2,
            py: 0.5,
            my: 2,
            backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
            borderColor: (theme) => theme.palette.error.main,
          }}
          variant="outlined"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <WarningRoundedIcon fontSize="inherit" color="error" />
            <Typography sx={{ ml: 1 }}>
              You need a default payment method to tip your trainer.
            </Typography>
          </Box>
          <Button
            size="small"
            onClick={() => {
              setPaymentMethodDialogOpen(true);
            }}
          >
            Add payment method
          </Button>
        </Card>
      )}
      <Typography variant="h3" sx={{ mb: 1 }}>
        Pick an amount or enter your own.
      </Typography>
      <Grid2 container spacing={2} sx={{ my: 2 }}>
        {tipAmounts.map((amount, index) => (
          <Grid2
            size={{
              xs: 3,
              sm: 2,
            }}
            key={amount}
          >
            <Card
              sx={{
                height: 60,
                backgroundColor: (theme) =>
                  index === selectedTipIndex
                    ? theme.palette.secondary.main
                    : undefined,
              }}
            >
              <CardActionArea
                onClick={() => {
                  setSelectedTipIndex(index);
                  setCustomTipAmount("");
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  height: "100%",
                  p: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    flex: 1,
                    lineHeight: "normal",
                  }}
                >
                  ${amount}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid2>
        ))}
        <Grid2
          size={{
            xs: 3,
            sm: 2,
          }}
        >
          <TextField
            fullWidth
            color={"secondary"}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ mr: 0 }} position="end">
                  $
                </InputAdornment>
              ),
            }}
            value={customTipAmount}
            onClick={() => {
              setSelectedTipIndex(null);
            }}
            onChange={(event) => {
              setCustomTipAmount(event.target.value);
            }}
            onBlur={() => {
              setAllowError(true);
            }}
            error={allowError && isInvalidTipAmount}
            helperText={
              allowError && isInvalidTipAmount
                ? "Must be a number between 10 and 500"
                : ""
            }
          />
        </Grid2>
      </Grid2>
      {tipTrainer.isError && (
        <Card
          sx={{
            px: 2,
            py: 0.5,
            my: 2,
            backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
            borderColor: (theme) => theme.palette.error.main,
            display: "flex",
            alignItems: "center",
          }}
          variant="outlined"
        >
          <WarningRoundedIcon fontSize="inherit" color="error" />
          <Typography sx={{ ml: 1 }}>
            Something went wrong. Please try again or contact support
            support@trainwell.net.
          </Typography>
        </Card>
      )}
      <Typography variant="caption" sx={{ display: "block", my: 1 }}>
        A portion of the tip will be retained by trainwell to cover processing
        fees
      </Typography>
      <Button
        startIcon={<CelebrationRoundedIcon />}
        onClick={() => {
          tipTrainer.mutate({
            data: {
              tipAmount: tipAmount,
              userId: client?.user_id ?? "",
            },
          });
        }}
        loading={tipTrainer.isPending}
        disabled={
          !defaultPaymentMethod ||
          isInvalidTipAmount ||
          (!customTipAmount && selectedTipIndex === null)
        }
        variant="contained"
      >
        Tip trainer
      </Button>
      <PaymentMethodDialog
        open={paymentMethodDialogOpen}
        onClose={() => {
          setPaymentMethodDialogOpen(false);
        }}
      />
      <Dialog
        open={tipTrainer.isSuccess}
        onClose={() => {
          tipTrainer.reset();
        }}
      >
        <DialogTitle>Success! Your trainer was tipped!</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              tipTrainer.reset();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
