import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { isBlank } from "@trainwell/features";
import { useCompany } from "@trainwell/features/company";
import {
  useCancelSubscription,
  useSubscriptions,
  useSwitchSubscription,
} from "@trainwell/features/subscriptions";
import { useTrainerMatches } from "@trainwell/features/trainers";
import { fromUnixTime, isPast } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import BootstrapDialogTitle from "src/components/materialWrappers/DialogTitle";
import LoadingComponent from "src/components/misc/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useSubscriptionGroupOptions } from "src/hooks/useSubscriptionGroupOptions";
import { hideChat, showChat } from "src/lib/frontChat";
import { api } from "src/lib/trainwellApi";
import { trackEvent } from "src/slices/analyticsSlice";
import { setNewTrainerID } from "src/slices/switchTrainerSlice";
import { ChangeTrainerCardSmall } from "../billing/ChangeTrainerCardSmall";
import { PauseDialog } from "./PauseDialog";
import { SwitchSubscriptionDialog } from "./SwitchSubscriptionDialog";

const cancelOptions: {
  reason: string;
  followUp: string;
}[] = [
  {
    reason: "Not seeing enough progress",
    followUp:
      "One last thing, what kind of progress were you hoping to achieve?",
  },
  {
    reason: "Not being held accountable",
    followUp:
      "One last thing, what would have helped you feel more accountable?",
  },
  // {
  //   reason: "Not getting enough guidance",
  //   followUp: "One last thing, what would you have liked more guidance on?",
  // },
  // {
  //   reason: "Not clicking with the trainer",
  //   followUp:
  //     "One last thing, what would have helped you click better with your trainer?",
  // },
  {
    reason: "Not enjoying workouts",
    followUp:
      "One last thing, what would have made your workouts more enjoyable?",
  },
  {
    reason: "Not happy with the tech",
    followUp:
      "One last thing, what kind of issues did you have? What features would you like to see?",
  },
  {
    reason: "Got what I came for",
    followUp:
      "We're so glad you got what you came for - can you tell us more about what that was?",
  },
  {
    reason: "Financial reasons",
    followUp:
      "One last thing, what kind of pricing would you have been comfortable with?",
  },
  {
    reason: "Other",
    followUp: "Please specify",
  },
];

type CancelStep =
  | "switch_trainer"
  | "confirm_understanding"
  | "pause_instead"
  | "tell_us_why"
  | "confirm";

const defaultSteps: CancelStep[] = [
  "confirm_understanding",
  "tell_us_why",
  "confirm",
];

// $69/mo
const cancelOfferPrice = import.meta.env.DEV
  ? "price_1QhyfLGWf3dtQxJnRbdhspgw"
  : "price_1N9Be4GWf3dtQxJnYSo8GiHX";

type Props = {
  planId: string;
  open: boolean;
  onClose: () => void;
};

export function CancelDialog({ planId, open, onClose }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState("");
  const [steps, setSteps] = useState<CancelStep[]>(defaultSteps);
  const [currentStep, setCurrentStep] = useState<CancelStep>(
    "confirm_understanding",
  );
  const currentStepIndex = steps.indexOf(currentStep);
  const [submitting, setSubmitting] = useState(false);
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
  const activeTests = useAppSelector((state) => state.analytics.activeTests);
  const client = useAppSelector((state) => state.client.client);
  const { data: subscriptions } = useSubscriptions({
    userId: client?.user_id ?? "",
  });
  const cancelSubscription = useCancelSubscription();
  const switchSubscription = useSwitchSubscription();
  const {
    data: trainerMatches,
    isPending: isPendingTrainerMatches,
    isError: isErrorTrainerMatches,
  } = useTrainerMatches({
    options: {
      userId: client?.user_id ?? "",
      numberOfCoaches: 3,
      changingCoach: true,
      generateJustification: false,
    },
    queryConfig: {
      enabled: open && Boolean(client?.user_id),
    },
  });
  const [confirmationChecks, setConfirmationChecks] = useState({
    loseDiscounts: false,
    noFreeTrial: false,
    noSameCoach: false,
    noCalls: false,
  });
  const [selectedCancelOptions, setSelectedCancelOptions] = useState<string[]>(
    [],
  );
  const [customCancelOption, setCustomCancelOption] = useState<string>("");
  const [switchOptionsDialogOpen, setSwitchOptionsDialogOpen] = useState(false);
  const subscriptionOptions = useSubscriptionGroupOptions();
  const { enqueueSnackbar } = useSnackbar();
  const { data: company } = useCompany();
  const [gratitudeDialogOpen, setGratitudeDialogOpen] = useState(false);

  const isInCheapCancelOfferTest = activeTests.includes(
    "dec_cheap_cancel_offer_treatment",
  );

  const showCancellationOffer = useMemo(() => {
    return company?.gabe_cancellation_offer_percent_to_show
      ? Math.random() < company.gabe_cancellation_offer_percent_to_show
      : false;
  }, [company?.gabe_cancellation_offer_percent_to_show]);

  const responseCancelOption = useMemo(() => {
    const selectedReasonsWithoutOther = selectedCancelOptions.filter(
      (o) => o !== "Other",
    );

    if (!selectedReasonsWithoutOther.length) {
      return null;
    }

    const randomSelectedReason =
      selectedReasonsWithoutOther[
        Math.floor(Math.random() * selectedReasonsWithoutOther.length)
      ];

    return cancelOptions.find((o) => o.reason === randomSelectedReason) ?? null;
  }, [selectedCancelOptions.length]);

  const offerPlanSwitch =
    client?.account.plan.subscription_group_id ===
      "e12e32c3-475f-44d5-bdc7-66a993d04843" &&
    client?.account.membership.state !== "past_due" &&
    selectedCancelOptions.includes("Other");

  const allConfirmed = Object.values(confirmationChecks).every(
    (check) => check === true,
  );

  const plan = subscriptions?.find((plan) => plan.id === planId);

  const isPreTrial = Boolean(
    plan?.trialEnd && !client?.account.dashboard.date_onboarded,
  );
  const isInTrial = Boolean(
    !isPreTrial && plan?.trialEnd && !isPast(fromUnixTime(plan.trialEnd)),
  );

  const enablePauseStep =
    plan &&
    client?.account.membership.state === "active" &&
    !plan.pausedUntil &&
    !plan.willPauseOn &&
    plan.interval !== "year" &&
    !isPreTrial &&
    !isInTrial;

  const enableSwitchTrainerStep = activeTests?.includes(
    "cancel_switch_prompt_treatment",
  );

  useEffect(() => {
    const newSteps = [...defaultSteps];

    if (enableSwitchTrainerStep) {
      newSteps.unshift("switch_trainer");
    }

    if (enablePauseStep) {
      // add pause_instead after confirm_understanding
      const index = newSteps.indexOf("confirm_understanding");
      newSteps.splice(index + 1, 0, "pause_instead");
    }

    setSteps(newSteps);
    setCurrentStep(newSteps[0]);
  }, [enablePauseStep, enableSwitchTrainerStep]);

  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  useEffect(() => {
    if (currentStep === "confirm" && offerPlanSwitch) {
      dispatch(
        trackEvent({
          event_type: "button_shown_three_month_to_one_month",
        }),
      );
    }
  }, [currentStep, offerPlanSwitch, dispatch]);

  useEffect(() => {
    if (
      currentStep === "tell_us_why" &&
      selectedCancelOptions.includes("Financial reasons") &&
      isInCheapCancelOfferTest &&
      client?.account.membership.state === "active"
    ) {
      dispatch(
        trackEvent({
          event_type: "dec_cancel_offer_shown",
        }),
      );
    }
  }, [currentStep, selectedCancelOptions, isInCheapCancelOfferTest, dispatch]);

  useEffect(() => {
    if (open) {
      dispatch(
        trackEvent({
          event_type: "cancel_dialog_opened",
        }),
      );
    }
  }, [open, dispatch]);

  function handleClose() {
    onClose();

    setReason("");
    setSubmitting(false);
  }

  function handleCancelPlan() {
    setSubmitting(true);

    cancelSubscription.mutate(
      {
        data: {
          subscription_id: planId,
          reason: reason,
          cancel_options: selectedCancelOptions,
          response_cancel_option:
            responseCancelOption?.reason.toLocaleLowerCase() ?? "",
          custom_cancel_option: selectedCancelOptions.includes("Other")
            ? customCancelOption
            : undefined,
        },
      },
      {
        onSuccess: () => {
          handleClose();
        },
        onError: (error) => {
          Sentry.captureException(error);

          setSubmitting(false);

          enqueueSnackbar({
            message: "Something went wrong. Please try again.",
            variant: "error",
          });
        },
      },
    );
  }

  if (!plan) {
    return null;
  }

  function handleNextStep() {
    const nextStep = steps[currentStepIndex + 1];
    setCurrentStep(nextStep);
  }

  return (
    <>
      <Dialog
        open={open && !pauseDialogOpen && !gratitudeDialogOpen}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          onClose={() => {
            handleClose();
          }}
        >
          {currentStep === "switch_trainer"
            ? "Before you cancel..."
            : `Cancel plan${plan.status === "past_due" ? " immediately" : ""}`}
        </BootstrapDialogTitle>
        {isPreTrial && (
          <Box
            sx={{
              background: "green",
              color: "white",
              textAlign: "center",
              padding: 1,
            }}
          >
            <Typography>
              <span style={{ fontWeight: "bold" }}>Remember:</span> Your free
              trial doesn&apos;t start until your first trainer call.
            </Typography>
          </Box>
        )}
        <DialogContent sx={{ pb: 0 }}>
          {currentStep === "switch_trainer" && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                Did you know you can switch trainers at any time for free?
              </DialogContentText>
              {isPendingTrainerMatches ? (
                <LoadingComponent message="Finding amazing trainers" />
              ) : isErrorTrainerMatches ? (
                <Typography>
                  There was an error loading your trainer matches
                </Typography>
              ) : (
                <Stack spacing={2}>
                  {trainerMatches?.coach_weights.map((match, i) => (
                    <ChangeTrainerCardSmall
                      key={match.trainer.trainer_id}
                      onSelectTrainer={(trainerId) => {
                        api.clients
                          .changeCoach({
                            userId: client!.user_id,
                            trainerId: trainerId,
                            reason: "manual_client",
                          })
                          .then(() => {
                            dispatch(setNewTrainerID(trainerId));

                            navigate("/schedule-call" + location.search);
                          });
                      }}
                      trainerId={match.trainer.trainer_id}
                    />
                  ))}
                </Stack>
              )}
            </>
          )}
          {currentStep === "confirm_understanding" && (
            <>
              <DialogContentText sx={{ fontWeight: "bold" }}>
                Please confirm that you understand the following:
              </DialogContentText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.loseDiscounts}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          loseDiscounts: event.target.checked,
                        })
                      }
                    />
                  }
                  label="I will lose all discounts, credits, and coupons."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.noFreeTrial}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          noFreeTrial: event.target.checked,
                        })
                      }
                    />
                  }
                  label="I will not get another free trial."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.noSameCoach}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          noSameCoach: event.target.checked,
                        })
                      }
                    />
                  }
                  label="I may not be able to work with the same trainer if I return."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.noCalls}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          noCalls: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Any upcoming calls I have with my trainer will be cancelled."
                />
              </FormGroup>
            </>
          )}
          {currentStep === "pause_instead" && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                Would you rather pause instead?
              </DialogContentText>
              <DialogContentText>
                You can pause your plan for up to 1 billing period and hold onto
                your trainer and any discounts.
              </DialogContentText>
            </>
          )}
          {currentStep === "tell_us_why" && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                We&apos;re sad to see you leave!
              </DialogContentText>
              <DialogContentText>
                Please let us know why you&apos;ll be leaving.
              </DialogContentText>
              <FormGroup>
                {cancelOptions.map((cancelOption, i) => (
                  <Box key={i}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCancelOptions.includes(
                            cancelOption.reason,
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedCancelOptions((old) => [
                                ...old,
                                cancelOption.reason,
                              ]);
                            } else {
                              setSelectedCancelOptions((old) =>
                                old.filter((o) => o !== cancelOption.reason),
                              );
                            }
                          }}
                        />
                      }
                      label={cancelOption.reason}
                    />
                    {cancelOption.reason === "Financial reasons" &&
                      isInCheapCancelOfferTest &&
                      selectedCancelOptions.includes(cancelOption.reason) &&
                      client?.account.membership.state === "active" && (
                        <Card
                          variant="outlined"
                          sx={{
                            backgroundColor: (theme) =>
                              alpha(theme.palette.primary.main, 0.1),
                            p: 1,
                            display: "flex",
                            my: 2,
                          }}
                        >
                          <WarningRoundedIcon color="primary" sx={{ mr: 1 }} />
                          <Box>
                            <Typography sx={{ mb: 1 }}>
                              Switch to a $69/mo plan and keep that discount
                              forever!
                            </Typography>
                            <Button
                              size="small"
                              variant="contained"
                              startIcon={<SwapHorizRoundedIcon />}
                              loading={switchSubscription.isPending}
                              onClick={() => {
                                dispatch(
                                  trackEvent({
                                    event_type: "dec_cancel_offer_claimed",
                                  }),
                                );

                                switchSubscription.mutate(
                                  {
                                    data: {
                                      newPriceId: cancelOfferPrice,
                                      scheduleForNextBillingDate: true,
                                      userId: client?.user_id ?? "",
                                    },
                                  },
                                  {
                                    onSuccess: () => {
                                      setGratitudeDialogOpen(true);
                                    },
                                    onError: (error) => {
                                      Sentry.captureException(error);

                                      enqueueSnackbar({
                                        message:
                                          "Something went wrong. Please try again.",
                                        variant: "error",
                                      });
                                    },
                                  },
                                );
                              }}
                            >
                              Switch to discounted plan
                            </Button>
                          </Box>
                        </Card>
                      )}
                  </Box>
                ))}
              </FormGroup>
              {selectedCancelOptions.includes("Other") && (
                <TextField
                  autoFocus
                  label="Please specify"
                  fullWidth
                  multiline
                  variant="outlined"
                  value={customCancelOption}
                  onChange={(event) => {
                    setCustomCancelOption(event.target.value);
                  }}
                  sx={{ my: 2 }}
                  helperText={
                    customCancelOption.length > 450 ? "Max 450 characters" : ""
                  }
                  error={customCancelOption.length > 450}
                />
              )}
            </>
          )}
          {currentStep === "confirm" && (
            <>
              <DialogContentText>
                We&apos;re sorry to hear that, but thanks for the honest
                feedback!
              </DialogContentText>
              {responseCancelOption && (
                <>
                  <DialogContentText sx={{ mt: 1 }}>
                    {responseCancelOption.followUp}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    placeholder="Type here"
                    fullWidth
                    multiline
                    minRows={2}
                    variant="outlined"
                    value={reason}
                    onChange={(event) => {
                      setReason(event.target.value);
                    }}
                    helperText={reason.length > 450 ? "Max 450 characters" : ""}
                    error={reason.length > 450}
                    sx={{ my: 2 }}
                  />
                </>
              )}
              {showCancellationOffer && company?.gabe_cancellation_offer && (
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.1),
                    p: 1,
                    display: "flex",
                    my: 2,
                  }}
                >
                  <WarningRoundedIcon color="primary" sx={{ mr: 1 }} />
                  <Box>
                    <Typography sx={{ mb: 1 }}>
                      {company.gabe_cancellation_offer}
                    </Typography>
                    <Button
                      size="small"
                      startIcon={<OpenInNewRoundedIcon />}
                      href="https://calendar.app.google/1fHWA5DXpTi5XVXW9"
                      target="_blank"
                    >
                      Schedule
                    </Button>
                  </Box>
                </Card>
              )}
              {offerPlanSwitch && (
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.1),
                    p: 1,
                    display: "flex",
                    my: 2,
                  }}
                >
                  <WarningRoundedIcon color="primary" sx={{ mr: 1 }} />
                  <Typography>
                    Wait, did you know you can switch to a monthly plan?
                  </Typography>
                </Card>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ mx: 4 }}>
          {currentStep === "switch_trainer" && (
            <Button variant="text" color="primary" onClick={handleNextStep}>
              Continue to cancel
            </Button>
          )}
          {currentStep === "confirm_understanding" && (
            <Button
              variant="text"
              color="primary"
              onClick={handleNextStep}
              disabled={!allConfirmed}
            >
              Next
            </Button>
          )}
          {currentStep === "pause_instead" && (
            <>
              <Button
                variant="text"
                onClick={() => {
                  setPauseDialogOpen(true);
                  dispatch(
                    trackEvent({
                      event_type: "pause_button_click",
                      event_content: { location: "cancel_dialog" },
                    }),
                  );
                }}
              >
                Pause plan
              </Button>
              <Button variant="text" color="primary" onClick={handleNextStep}>
                Cancel plan
              </Button>
            </>
          )}
          {currentStep === "tell_us_why" && (
            <Button
              variant="text"
              color="primary"
              onClick={handleNextStep}
              disabled={
                selectedCancelOptions.length === 0 ||
                (selectedCancelOptions.includes("Other") &&
                  (!customCancelOption || customCancelOption.length > 450))
              }
            >
              Next
            </Button>
          )}
          {currentStep === "confirm" && (
            <>
              {offerPlanSwitch && (
                <Button
                  onClick={() => {
                    setSwitchOptionsDialogOpen(true);

                    dispatch(
                      trackEvent({
                        event_type: "button_pressed_three_month_to_one_month",
                      }),
                    );
                  }}
                >
                  See monthly price
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => {
                  handleCancelPlan();
                }}
                disabled={Boolean(
                  responseCancelOption &&
                    (isBlank(reason) || reason.length > 450),
                )}
                loading={submitting}
                color="error"
              >
                Cancel plan{plan.status === "past_due" ? " immediately" : ""}
              </Button>
            </>
          )}
        </DialogActions>
        <Stepper activeStep={currentStepIndex} sx={{ my: 3 }} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={i}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Dialog>
      <Dialog
        open={gratitudeDialogOpen}
        onClose={() => {
          setGratitudeDialogOpen(false);
          handleClose();
        }}
      >
        <BootstrapDialogTitle
          onClose={() => {
            setGratitudeDialogOpen(false);
            handleClose();
          }}
        >
          Thank You for Sticking with Us! ☺️
        </BootstrapDialogTitle>
        <DialogContent>
          We&apos;re so grateful you&apos;ve chosen to continue your fitness
          journey with us! 🎉
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setGratitudeDialogOpen(false);
              handleClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <PauseDialog
        open={pauseDialogOpen}
        onClose={() => {
          setPauseDialogOpen(false);
          handleClose();
        }}
        planId={planId}
      />
      {offerPlanSwitch && (
        <SwitchSubscriptionDialog
          open={switchOptionsDialogOpen}
          subOptions={subscriptionOptions}
          onClose={() => {
            setSwitchOptionsDialogOpen(false);
            handleClose();
          }}
        />
      )}
    </>
  );
}
