import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import type { SubscriptionGroupOption } from "@trainwell/features/subscription-groups";
import {
  useSubscriptions,
  useSwitchSubscription,
} from "@trainwell/features/subscriptions";
import { ErrorDialog } from "@trainwell/ui";
import { format, fromUnixTime } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { formatCentsToDollars } from "src/lib/misc";

type Props = {
  open: boolean;
  subOptions: SubscriptionGroupOption[];
  onClose: () => void;
};

export function SwitchSubscriptionDialog({ open, subOptions, onClose }: Props) {
  const [pickedSubOption, setPickedSubOption] =
    useState<SubscriptionGroupOption | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const clientId = useAppSelector((state) => state.client.client?.user_id);
  const { data: subscriptions } = useSubscriptions({
    userId: clientId ?? "",
  });
  const plan = subscriptions?.find(
    (plan) =>
      plan.status === "active" ||
      plan.status === "trialing" ||
      plan.status === "past_due",
  );
  const switchSubscription = useSwitchSubscription();
  const { enqueueSnackbar } = useSnackbar();

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  if (!plan) {
    return (
      <ErrorDialog
        buttonCTA="Close"
        onClick={() => {
          onClose();
        }}
      />
    );
  }

  const planWillChangeImmediatly =
    plan.status === "trialing" || plan.status === "past_due";

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>Subscription plans</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          {activeStep === 0 && (
            <DialogContentText>
              {plan.status === "trialing"
                ? ""
                : "Your subscription will update at the end of the current billing cycle."}
            </DialogContentText>
          )}
          {activeStep === 0 &&
            subOptions.map((subOption, i) => {
              const isCurrent =
                subOption.stripe_price_id === plan.stripe_price_id;

              return (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    my: 1,
                    mb: 2,
                  }}
                  key={i}
                >
                  <Grid item display="flex" alignItems="center" xs={12} sm={5}>
                    <Typography>{subOption.title}</Typography>
                  </Grid>
                  <Grid item sx={{ textAlign: "start" }} xs={8} sm={5}>
                    <Typography>
                      {formatCentsToDollars(getPrice(subOption)) +
                        getPerString(subOption)}
                    </Typography>
                    {/* <Typography variant="caption">{subOption.details}</Typography> */}
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <Button
                      onClick={() => {
                        setPickedSubOption(subOption);
                        handleNext();
                      }}
                      variant="text"
                      disabled={isCurrent}
                    >
                      {isCurrent ? "Current" : "Switch"}
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
          {activeStep === 1 && pickedSubOption && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                {planWillChangeImmediatly
                  ? "Your subscription will update to the plan you chose."
                  : "Your subscription will update to the plan you chose at the end of the billing cycle."}
              </DialogContentText>
              <DialogContentText>
                {`You will be charged ${
                  formatCentsToDollars(getPrice(pickedSubOption)) +
                  getPerString(pickedSubOption)
                } starting ${
                  plan.status === "past_due"
                    ? "today"
                    : `on ${format(
                        fromUnixTime(plan.currentPeriodEnd),
                        "MMMM d, yyyy",
                      )}`
                }.`}
              </DialogContentText>
            </>
          )}
          {activeStep === 2 &&
            (switchSubscription.isPending ? (
              <Box
                sx={{ display: "flex", justifyContent: "center", pb: 5, pt: 3 }}
              >
                <CircularProgress></CircularProgress>
              </Box>
            ) : (
              <>
                <DialogContentText>
                  {planWillChangeImmediatly
                    ? "Your subscription has succesfully updated."
                    : `Your subscription has succesfully been set to update to your new plan on ${format(
                        fromUnixTime(plan.currentPeriodEnd),
                        "MMMM d, yyyy",
                      )}.`}
                  {}
                </DialogContentText>
              </>
            ))}
        </DialogContent>
        <Stepper activeStep={activeStep} sx={{ mt: 2 }} alternativeLabel>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
        <DialogActions sx={{ mx: 4 }}>
          <Button
            variant="text"
            onClick={() => {
              onClose();
            }}
            disabled={switchSubscription.isPending}
          >
            Close
          </Button>

          {activeStep === 1 && (
            <Button
              variant="text"
              onClick={() => {
                handleBack();
              }}
              disabled={switchSubscription.isPending}
            >
              Back
            </Button>
          )}
          {activeStep === 1 && (
            <Button
              variant="text"
              loading={switchSubscription.isPending}
              onClick={() => {
                if (pickedSubOption) {
                  switchSubscription.mutate(
                    {
                      data: {
                        userId: clientId ?? "",
                        newPriceId: pickedSubOption.stripe_price_id,
                        scheduleForNextBillingDate: true,
                      },
                    },
                    {
                      onError: (error) => {
                        Sentry.captureException(error);

                        enqueueSnackbar({
                          variant: "error",
                          message: "An error occurred. Please try again later.",
                        });
                      },
                      onSuccess: () => {
                        handleNext();
                      },
                    },
                  );
                }
              }}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

const getPrice = (subOption: SubscriptionGroupOption) => {
  return (
    (subOption.interval === "month"
      ? subOption.monthly_price * subOption.interval_count
      : subOption.interval === "year"
        ? subOption.monthly_price * 12
        : 0) * 100
  );
};

const getPerString = (subOption: SubscriptionGroupOption) => {
  return (
    ` per ${subOption.interval_count > 1 ? subOption.interval_count : ""} ` +
    subOption.interval +
    (subOption.interval_count > 1 ? "s" : "")
  );
};
