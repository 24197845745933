import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import type { Invoice } from "@trainwell/features/invoices";
import { format, fromUnixTime } from "date-fns";
import React from "react";
import { formatCentsToDollars } from "src/lib/misc";

const invoiceStatus: Record<string, string> = {
  draft: "Draft",
  open: "Open",
  paid: "Paid",
  void: "Void",
  uncollectible: "Uncollectible",
};

type Props = {
  invoice: Invoice;
  sx?: SxProps<Theme>;
};

export function InvoiceCell({ sx = [], invoice }: Props) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const menuOpen = Boolean(menuAnchorEl);

  let dateString = format(
    fromUnixTime(invoice.dateChargeCreated ?? invoice.periodEnd),
    "MMM d, yyyy",
  );

  if (invoice.status === "open" || invoice.status === "draft") {
    dateString = format(fromUnixTime(invoice.dateCreated), "MMM d, yyyy");
  }

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <Typography>{formatCentsToDollars(invoice.amountDue)}</Typography>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            {dateString}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Stack spacing={1} direction="row">
            {invoice.refunded ? (
              <Chip
                size="small"
                color={"default"}
                label={"Refunded"}
                icon={<KeyboardReturnRoundedIcon />}
              />
            ) : invoice.disputed ? (
              <Chip
                size="small"
                color={"warning"}
                label={"Disputed"}
                icon={<WarningRoundedIcon />}
              />
            ) : (
              <>
                {invoice.status && (
                  <Chip
                    size="small"
                    color={invoice.status === "paid" ? "success" : "default"}
                    label={invoiceStatus[invoice.status]}
                  />
                )}
              </>
            )}
          </Stack>
        </Grid>
        {invoice.invoicePdf && (
          <Grid item xs={3}>
            <Button
              variant="text"
              onClick={(event) => {
                setMenuAnchorEl(event.currentTarget);
              }}
              id="menu-button"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
            >
              View
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={() => {
                setMenuAnchorEl(null);
              }}
              MenuListProps={{
                "aria-labelledby": "menu-button",
              }}
            >
              <Link
                href={invoice.invoicePdf}
                sx={{
                  textDecoration: "none",
                  color: (theme) => theme.palette.text.primary,
                }}
                onClick={() => {
                  setMenuAnchorEl(null);
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <PictureAsPdfRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  Download invoice pdf
                </MenuItem>
              </Link>
              {invoice.receiptUrl && (
                <Link
                  href={invoice.receiptUrl}
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    color: (theme) => theme.palette.text.primary,
                  }}
                  onClick={() => {
                    setMenuAnchorEl(null);
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <ReceiptRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Receipt
                  </MenuItem>
                </Link>
              )}
            </Menu>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
